<template>
  <b-row>
    <b-col md="9">
      <div>
        <validation-provider
          #default="{ errors }"
          name="Services"
          rules="required"
        >
          <b-form-group
            label="Services"
            label-for="services"
            class="required-data"
            :state="errors.length > 0 ? false:null"
          >

            <v-select
              id="services"
              v-model="currentService"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="servicesOption"
              placeholder=""
              name="state"
              class="font-small-4 mb-1 services-select"
              :disabled="readOnly"
            />
            <small
              class="text-danger"
              :state="errors.length > 0 ? false:null"
            >
              {{ errors[0] }}
            </small>
          </b-form-group>
        </validation-provider>
      </div>
    </b-col>
    <b-col class="d-flex align-items-center">
      <div
        v-if="!readOnly"
        class="cursor-pointer"
        @click="$emit('deleteService', index)"
      >
        <img src="../../../assets/images/logo/basket.png">
      </div>
    </b-col>
    <hr>
  </b-row>
</template>

<script>
import {BCol, BRow, BFormGroup } from "bootstrap-vue";
import vSelect from "vue-select";
import {mapGetters} from "vuex";
import { ValidationProvider } from "vee-validate";

export default {
  components: {
    BRow,
    BCol,
    vSelect,
    BFormGroup,
    ValidationProvider,
  },
  props: {
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      currentService: "",
      readOnly: false,
      servicesOption: [
        {
          title: "Activity Check",
          value: "Activity Check",
        },
        {
          title: "Surveillance - 1 Investigator",
          value: "Surveillance - 1 Investigator",
        },
        {
          title: "Surveillance - 2 Investigator",
          value: "Surveillance - 2 Investigator",
        },
        {
          title: "Alive and Wellness Check",
          value: "Alive and Wellness Check",
        },
        {
          title: "Unmanned Surveillance (USP)",
          value: "Unmanned Surveillance (USP)",
        },
        {
          title: "NetSweep / Social Media Research",
          value: "NetSweep / Social Media Research",
        },
        {
          title: "Medical Canvassing",
          value: "Medical Canvassing",
        },
        {
          title: "Background Investigation",
          value: "Background Investigation",
        },
        {
          title: "Claims Investigations",
          value: "Claims Investigations",
        },
        {
          title: "SIU",
          value: "SIU",
        },
        {
          title: "EDR",
          value: "EDR",
        },
        {
          title: "Other",
          value: "Other",
        }
      ],
    }
  },
  computed: {
    ...mapGetters({
      getRequestedServices: "cases/getRequestedServices",
      getIsReadOnly: "cases/getIsReadOnly",
    }),
    getRequestedServicesData() {
      return this.getRequestedServices
    },
    isReadOnly() {
      return this.getIsReadOnly;
    },
  },
  watch: {
    getRequestedServicesData(val) {
      this.fillRequestedServices(val);
    },
    isReadOnly(val) {
      this.readOnly = val
    },
    currentService(val) {
      if (val && this.getRequestedServices.indexOf(val.value) === -1) this.$emit("updateService", val, this.index);
    },
  },
  async created() {
    this.readOnly = this.getIsReadOnly;
    try {
      this.fillRequestedServices(this.getRequestedServices);
    } catch (err) {
      this.error = err
    }
  },
  methods: {
    fillRequestedServices() {
      const items = [];
      const services = this.getRequestedServices.split(',');
      services.map((item) => {
        items.push({title: item, value: item})
      })
      this.currentService = items[this.index]
    }
  },
}
</script>

<style scoped>

</style>